<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ search_item_history_item.item.name }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ userConf.date_range.startDate | moment }} - {{ userConf.date_range.endDate | moment }}
        </span>
      </div>
      <div class="col-xl-8 ">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header h-auto border-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_items.item.timeline.title") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <span class="text-primary font-weight-bolder font-size-h3"
                ><i
                  class="fa fa-search text-primary font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(search_item_history_item.queries).toLocaleString()
                }}

                &nbsp;/&nbsp;
                <i
                  class="fa fa-users text-primary font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(
                    search_item_history_item.unique_queries
                  ).toLocaleString()
                }}</span
              >
            </div>
          </div>
          <!--begin::Body-->
          <div class="card-body p-0">
            <div v-if="this.loading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin:auto;background:#fff;display:block;"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(20 50)">
                  <circle cx="0" cy="0" r="6" fill="#7b0000">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.375s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(40 50)">
                  <circle cx="0" cy="0" r="6" fill="#c34c37">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.25s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(60 50)">
                  <circle cx="0" cy="0" r="6" fill="#fd7f64">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.125s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(80 50)">
                  <circle cx="0" cy="0" r="6" fill="#ffbea0">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="0s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
              </svg>
            </div>
            <div
              v-else-if="
                graphData && graphData[0].data && graphData[0].data.length > 0
              "
            >
              <!--begin::Chart-->
              <apexchart
                class="card-rounded-bottom"
                :options="chartOptions"
                :series="graphData"
                type="area"
                height="180"
                width="100%"
              ></apexchart>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <div class="card-body text-center">
                  <div class="row">
                    <div class="col-sm-12">
                      <h2>
                        {{ $t("analytics.trending_searches.item.no_result") }}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_items.item.table.title") }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <div
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="search_item_history_item.table"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    :loading-text="
                      $t('analytics.trending_items.item.table.loading')
                    "
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.queries="{ item }">
                      {{ parseInt(item.queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.unique_queries="{ item }">
                      {{ parseInt(item.unique_queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.avg_position="{ item }">
                      {{
                        parseFloat(item.avg_position).toLocaleString("cs-CZ", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </template>
                    <template v-slot:item.term="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-searches-item',
                          query: { term: item.term }
                        }"
                      >
                        {{ item.term }}
                      </router-link>
                    </template>
                    <template v-slot:item.result_setting_type_id="{ item }">
                      {{
                        item.result_setting_type_id == 1
                          ? "Autocomplete"
                          : "Search"
                      }}
                    </template>
                    <template v-slot:top>
                      <div
                        class="d-flex align-items-center position-relative my-1"
                      >
                        <span
                          class="svg-icon svg-icon-1 position-absolute ms-6"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <rect
                              opacity="0.5"
                              x="17.0365"
                              y="15.1223"
                              width="8.15546"
                              height="2"
                              rx="1"
                              transform="rotate(45 17.0365 15.1223)"
                              fill="black"
                            ></rect>
                            <path
                              d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          v-model="search"
                          :placeholder="
                            $t(
                              'analytics.trending_items.item.table.search_field'
                            )
                          "
                          class="form-control form-control-solid w-250px ps-14"
                        />
                      </div>
                    </template>

                    <template v-slot:no-data>
                      {{ $t("common.nodata") }}
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import { LOAD_SEARCH_HISTORY_ITEM_ITEM } from "@/core/services/store/search_history.module";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";

export default {
  components: {UsStatsConfiguration},
  data() {
    return {
      chartOptions: {
        noData: {
          text: "Loading...",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#000000",
            fontSize: "14px",
            fontFamily: "Helvetica"
          }
        },
        chart: {
          type: "bar",
          height: 180,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2
        },
        xaxis: {
          type: "datetime"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: true,
            format: "dd. MM h:00:00",
            formatter: undefined
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString();
            }
          }
        }
      },
      options: {},
      search: "",
      id: this.$route.query.id,
      headers: [
        { text: "#", value: "row" },
        {
          text: this.$t(
            "analytics.trending_items.item.table.headers.result_type"
          ),
          value: "result_setting_type_id"
        },
        {
          text: this.$t(
            "analytics.trending_items.item.table.headers.position_avg"
          ),
          value: "avg_position"
        },
        {
          text: this.$t("analytics.trending_items.item.table.headers.query"),
          value: "term"
        },
        {
          text: this.$t("analytics.trending_items.item.table.headers.queries"),
          value: "queries"
        },
        {
          text: this.$t(
            "analytics.trending_items.item.table.headers.unique_queries"
          ),
          value: "unique_queries"
        }
      ]
    };
  },
  watch: {
    currentUserConfiguration: {
      handler: function () {
        this.reloadData();

      },
      deep: true,
    },
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    userConf() {
      return this.currentUserConfiguration.stats;
    },
    graphData() {
      return [
        {
          name: this.$t("analytics.trending_items.item.timeline.data_title"),
          data: this.search_item_history_item.timeline.data
        }
      ];
    },
    ...mapState({
      search_item_history_item: state =>
        state.searchHistory.tables.search_item_history_item,
      loading: state => state.searchHistory.loading
    }),
    ...mapGetters(["currentProject", "currentUserConfiguration"])
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    reloadData() {
      const data = [];
      if (this.userConf.result_object === "search" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=2");
      }
      if (this.userConf.result_object === "autocomplete" || this.userConf.result_object === "all") {
        data.push("result_setting_type_id[]=1");
      }

      if (this.userConf.date_range.startDate) {
        data.push(
            `start=${moment(this.userConf.date_range.startDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.userConf.date_range.endDate) {
        data.push(`end=${moment(this.userConf.date_range.endDate).format("yyyy-MM-DD")}`);
      }
      if (this.userConf.language) {
        data.push(`language=${this.userConf.language}`);
      }
      if (this.id) {
        data.push(`id=${this.id}`);
      }

      this.$store.dispatch(LOAD_SEARCH_HISTORY_ITEM_ITEM, data);
    },
  }
};
</script>
